import React, { FC, HTMLAttributes, useMemo, useState } from "react";
import styled from "styled-components";
import Content from "components/front/corporate/atoms/Content";
import Headline from "components/front/corporate/atoms/Headline";
import { News } from "interfaces/front/page/corporate";
import { useActivation } from "lib/hooks/useActivation";

export type Props = HTMLAttributes<HTMLElement> & { newsList: News[] };
const NewsSection: FC<Props> = ({ newsList, ...props }) => {
  const [tab, setTab] = useState<"すべて" | "お知らせ" | "プレスリリース">("すべて");
  const [seesMore, seeMore] = useActivation(false);
  const filteredList = useMemo(() => {
    const filtered = newsList.filter((news) => news.category === tab || tab === "すべて");
    return seesMore ? filtered : filtered.slice(0, 6);
  }, [newsList, tab, seesMore]);
  return (
    <section {...props}>
      <Headline>ニュース</Headline>
      <Content>
        <CategoryTabs>
          <CategoryTab $selected={tab === "すべて"} onClick={() => setTab("すべて")}>
            <TabButton>すべて</TabButton>
          </CategoryTab>
          <CategoryTab $selected={tab === "お知らせ"} onClick={() => setTab("お知らせ")}>
            <TabButton>お知らせ</TabButton>
          </CategoryTab>
          <CategoryTab $selected={tab === "プレスリリース"} onClick={() => setTab("プレスリリース")}>
            <TabButton>プレスリリース</TabButton>
          </CategoryTab>
        </CategoryTabs>
        <NewsList>
          {filteredList.map((news, index) => (
            <NewsArea key={index}>
              <DateArea>{news.announce_at}</DateArea>
              <CategoryArea>
                <Badge>{news.category}</Badge>
              </CategoryArea>
              <NewsText dangerouslySetInnerHTML={{ __html: news.content.replace(/\n/g, "<br />") }} />
            </NewsArea>
          ))}
        </NewsList>
        {!seesMore && <SeeMoreButton onClick={seeMore}>もっと見る</SeeMoreButton>}
      </Content>
    </section>
  );
};
export default NewsSection;

const CategoryTabs = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 960px) {
    font-size: 14px;
  }
  @media screen and (min-width: 961px) {
    font-size: 16px;
  }
`;
const CategoryTab = styled.div<{ $selected?: boolean }>`
  width: 160px;
  text-align: center;
  border-bottom: ${({ $selected }) => ($selected ? "1px solid white" : "none")};
  cursor: pointer;
  color: ${({ $selected }) => ($selected ? "white" : "#666")};

  &:hover {
    color: white;
  }

  @media screen and (max-width: 960px) {
    padding: 20px 10px;
  }
  @media screen and (min-width: 961px) {
    padding: 20px;
  }
`;
const TabButton = styled.a`
  color: inherit;
  display: block;
  transition: color 0.4s;
`;
const NewsList = styled.ul`
  @media screen and (max-width: 960px) {
    font-size: 14px;
  }
  @media screen and (min-width: 961px) {
    font-size: 16px;
  }
`;
const NewsArea = styled.li`
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border-bottom: 1px solid #666;

  &:first-of-type {
    border-top: 1px solid #666;
  }

  @media screen and (max-width: 960px) {
    padding: 20px;
  }
  @media screen and (min-width: 961px) {
    width: 100%;
    padding: 30px;
    display: table;
  }
`;
const DateArea = styled.div`
  color: #ccc;
  @media screen and (max-width: 960px) {
    font-size: 0.9em;
    padding: 6px 0;
    display: inline-block;
  }
  @media screen and (min-width: 961px) {
    width: 110px;
    display: table-cell;
    vertical-align: middle;
  }
`;
const CategoryArea = styled.div`
  @media screen and (max-width: 960px) {
    display: inline-block;
    margin-left: 10px;
  }
  @media screen and (min-width: 961px) {
    width: 160px;
    display: table-cell;
    vertical-align: middle;
  }
`;
const Badge = styled.span`
  transform: skewX(-15deg);
  width: 130px;
  text-align: center;
  font-size: 0.9em;
  display: inline-block;
  padding: 5px;
  border: 1px solid #ccc;
  color: #ccc;
`;
const NewsText = styled.p`
  font-weight: normal;
  margin-top: 5px;
  line-height: 1.428em;

  a {
    color: white;
    text-decoration: underline;
  }

  @media screen and (max-width: 960px) {
    margin-top: 10px;
    display: block;
  }
  @media screen and (min-width: 961px) {
    display: table-cell;
    vertical-align: middle;
    font-size: 16px;
  }
`;

const SeeMoreButton = styled.div`
  cursor: pointer;
  margin-top: 40px;
  text-align: center;
  text-decoration: underline;
  @media screen and (max-width: 960px) {
    margin-top: 20px;
  }
`;
